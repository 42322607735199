<template>
  <div class="mt-10">
    <pro-tabs :tabs="tabs">
      <pro-tab slot-scope="{ tabs, selectTab, currentTab }">
        <div class="flex flex-row sm:flex-col">
          <div
            v-for="tab in tabs"
            :key="tab.name"
            class="mr-24 font-bold capitalize text-gray-800 sm:my-2"
            :class="{
              'border-b-tab text-promy-green-300': tab.selected,
            }"
          >
            <a @click="selectTab(tab)" class="cursor-pointer">{{ tab.name }}</a>
          </div>
        </div>
        <hr class="mt-2" />

        <TabsInfos :infos="findTabByName(currentTab)" />
      </pro-tab>
    </pro-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { filter, find, forEach, map } from 'lodash'
import helpers from '@/mixins/helpers'
import TabsInfos from './TabInfos'
import axios from 'axios'

export default {
  data() {
    return {
      tabs: [],
      AllTabsInfos: {},
    }
  },
  mixins: [helpers],
  components: {
    TabsInfos,
  },
  computed: {
    ...mapGetters({
      localisations: 'dossierpromoteur/getLocalisation',
      types: 'poi/types',
      informationsStore: 'dossierpromoteur/getinfos',
    }),
  },
  created() {
    this.getTabs()

    forEach(this.tabs, (tab) => {
      let currentTab = tab.name.toLowerCase().replace(/ /g, '-')
      this.AllTabsInfos[currentTab] = this.getTypeInfos(currentTab)
    })
  },
  mounted() {},
  methods: {
    findTabByName(name) {
      return find(this.tabs, (tab) => {
        let nameToLowerCase = tab.name.toLowerCase().replaceAll(/ /g, '-')
        return _.deburr(nameToLowerCase) === name
      }).infos
    },
    getTabs() {
      this.tabs = Array.from(this.types, (type) => {
        let currentTab = type.label.toLowerCase().replaceAll(/ /g, '-')
        return {
          name: type.label,
          selected: false,
          infos: this.getTypeInfos(currentTab),
        }
      })
      this.tabs[0].selected = true
    },
    getTypeInfos(label) {
      let vm = this
      let type = filter(
        this.types,
        (type) => label === type.label.toLowerCase().replaceAll(' ', '-'),
      )[0]

      const subkeysType = map(type.subKeys, (subkey) => subkey.key)
      const filtered = Object.keys(this.localisations)
        .filter((key) => type.keys.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.localisations[key]
          return obj
        }, {})
      let removed_unused_subkeys = {}
      forEach(filtered, (value) => {
        forEach(value, (sub_value, sub_key) => {
          let filteredBydistance = filter(
            sub_value,
            (item) => item.distance_en_metre <= 3000,
          )
          filteredBydistance = filteredBydistance.length
            ? filteredBydistance
            : sub_value

          if (subkeysType.includes(sub_key) && filteredBydistance.length) {
            filteredBydistance = map(filteredBydistance, (item) => {
              if (!Array.isArray(item['coordonnee_gps'])) {
                item['coordonnee_gps'] = item['coordonnee_gps']
                  .split('(')[1]
                  .split(')')[0]
                  .split(' ')
              }
              return item
            })
            forEach(filteredBydistance, (item, index) => {
              item['walking'] = {}
              setTimeout(function () {
                vm.getDirection('walking', item['coordonnee_gps']).then(
                  (response) => {
                    item['walking'] = response
                  },
                )
              }, index * 200)
            })
            removed_unused_subkeys[sub_key] = {
              localisation: filteredBydistance,
              infos: this.findByKey(type.subKeys, 'marker', sub_key),
            }
          }
        })
      })

      return removed_unused_subkeys
    },
    getDirection(
      profile,
      end,
      start = this.informationsStore.center_parcelle_principale,
    ) {
      let vm = this
      let directions = new Promise((resolve, reject) => {
        axios
          .get(
            `${vm.$mapbox_directions}${profile}/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&overview=full&access_token=${vm.$key_mapbox}`,
          )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            vm.$toaster.warning('itineraire non disponible')
          })
      })
      return directions.then((response) => {
        let route = response.data.routes[0].legs[0]
        return {
          distance: route.distance,
          duration: route.duration,
        }
      })
    },

    findByKey(array, key, tosearch) {
      return find(array, (x) => {
        if (x.key === tosearch) {
          return x.marker
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.border-b-tab {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    border-bottom: 1px solid #00b7ab;
    width: 100%;
    bottom: -0.5rem;
    right: 0px;
  }
}
</style>
