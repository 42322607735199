<template>
  <div>
    <accordion
      v-if="localisations.length"
      class="w-full rounded-lg accordion-ipad"
    >
      <!-- <div class="mt-4" v-for="(info, idx) in localisations" :key="idx"> -->

      <accordion-item
        class="shadow-md my-2 border-b-0"
        v-for="(loc, index) in localisations"
        :key="getIndex(markerInfo.marker, index)"
        :indice="getIndex(markerInfo.marker, index)"
      >
        <template slot="accordion-trigger">
          <a
            class="flex justify-between w-full py-3 px-5 cursor-pointer bg-white rounded-md"
            :class="{
              'bg-promy-gray-100 rounded-b-none border-b-0 border-promy-gray-250':
                active_accordion === getIndex(markerInfo.marker, index),
            }"
            @click="activingAccordion(getIndex(markerInfo.marker, index))"
          >
            <div class="w-1/2 flex justify-start">
              <img
                :src="'/images/markers/commodite/' + markerInfo.marker + '.svg'"
                alt=""
                class="w-6"
              />
              <span
                class="ml-4 text-promy-gray-750 font-extrabold text-sm flex items-center"
                >{{ markerInfo.name }}
              </span>
            </div>
            <div class="flex w-1/2 justify-end items-center">
              <div v-if="loc.walking" class="flex items-center">
                <span class="flex justify-center w-4"
                  ><img src="/images//markers/stickman-black.svg"
                /></span>
                <span v-if="loc.walking" class="ml-2 text-sm">
                  {{ SecondsToMin(loc.walking.duration) }} min à pied</span
                >
              </div>
              <div
                class="h-8 w-8 rounded-full flex justify-center items-center ml-8"
              >
                <i
                  class="fas"
                  :class="{
                    'fa-chevron-up':
                      active_accordion === getIndex(markerInfo.marker, index),
                    'fa-chevron-down':
                      active_accordion !== getIndex(markerInfo.marker, index),
                  }"
                ></i>
              </div>
            </div>
          </a>
        </template>
        <template slot="accordion-content">
          <hr class="mx-auto border-promy-gray-250" style="width: 97%" />
          <div
            class="bg-promy-gray-100 pt-4 pb-6 px-6 flex justify-between sm:flex-col"
          >
            <div class="flex flex-col">
              <p class="text-promy-gray-700 font-arial text-sm font-bold">
                {{ loc.name ? loc.name : 'N.C' }} - {{ markerInfo.name }}
              </p>
              <p
                v-if="loc.walking"
                class="text-promy-gray-700 font-arial text-sm font-bold"
              >
                Distance: {{ loc.walking.duration | roundDouble }}m
              </p>
            </div>
            <div class="flex">
              <button
                class="text-promy-green-300 flex items-center"
                @click="moveToMap(markerInfo, loc)"
              >
                <span class="text-sm font-semibold">
                  Localiser sur la map
                </span>
                <div
                  class="w-8 h-8 flex items-center justify-center rounded-full bg-promy-green-300 ml-2"
                >
                  <i class="fas fa-map-marker-alt text-white" />
                </div>
              </button>
            </div>
          </div>
        </template>
      </accordion-item>
    </accordion>

    <div class="mt-4 font-bold" v-else>Aucune localisation n'existe</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Mapbox from '@/mixins/mapbox'

export default {
  mixins: [Mapbox],
  data() {
    return {
      marker_current_loc: null,
      active_accordion: -1,
      profiles: ['driving', 'walking'],
      dataInfos: {},
    }
  },
  props: {
    infos: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      map: 'defaultmap/map',
      current_position: 'localisation/current_position',
    }),
    localisations() {
      return Object.values(this.infos).length
        ? Object.values(this.infos)[0].localisation
        : []
    },
    markerInfo() {
      return Object.values(this.infos).length
        ? Object.values(this.infos)[0].infos
        : []
    },
  },
  methods: {
    activingAccordion(idx) {
      this.active_accordion = this.active_accordion === idx ? -1 : idx
    },

    getIndex(value, index) {
      return value + '-' + index
    },
    SecondsToMin(val) {
      return (val / 60).toFixed(2)
    },
    moveToMap(infos, loc) {
      let coords = [
        parseFloat(loc.coordonnee_gps[0]),
        parseFloat(loc.coordonnee_gps[1]),
      ]
      this.$store.commit('localisation/CURRENT_POSITION', {
        categorie: infos.key,
        coords: coords,
      })
    },
  },
  watch: {
    infos: {
      immediate: true,
      handler(newVal, oldVal) {
        this.dataInfos = newVal
      },
    },
  },
}
</script>
